import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import './Navbar.css';

export const Navbar = (): JSX.Element => {

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const location: string = useLocation().pathname;

    const isActive = (path: string): string => {
        return location === path ? 'active' : '';
    };

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };

    const barClass = isNavbarOpen ? 'active' : '';

    return (
        <header className={location === '/' ? 'nav-wrap' : 'nav-wrap nav-background'}>
            <nav className={(location === '/menu' || location === '/drinks') ? 'navbar navbar-expand-lg navbar-light white-bg' : 'navbar navbar-expand-lg navbar-light beige-bg'} id="mainNav">
                <div className="container">
                    {/* Logo */}
                    <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                        <img src="/img/logo/logo.png"
                            height="47"
                            alt="Hallwylska golden logo" />
                    </Link>

                    {/* Button toggler for mobile */}
                    <div className="burger-box" onClick={() => toggleNavbar()}>
                        <div className={`bar bar1 ${barClass}`}></div>
                        <div className={`bar bar2 ${barClass}`}></div>
                        <div className={`bar bar3 ${barClass}`}></div>
                    </div>

                    {/* DESKTOP NAV */}
                    <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} 
                        id="navbarSupportedContent">
                        {/* Left nav */}
                        <ul className={`navbar-nav ms-auto 
                            mb-2 mb-lg-0 d-flex align-items-center`}>
                            <li className={`nav-item ${isActive('/about')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/about">
                                    About
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/julbord')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/julbord">
                                    Julbord
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/menu')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/menu">
                                    Meny
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/drinks')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/drinks">
                                    Drinkmeny
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/calendar')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/calendar">
                                    Calendar
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/team')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/team">
                                    The Team
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/event')}`}>
                                <Link className="nav-link" onClick={closeNavbar}
                                    to="/event">
                                    Event
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
