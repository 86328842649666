import React from "react";

import { Helmet } from "react-helmet";

import './Julbord.css';
import Button from "../../components/button/Button";

export const Julbord: React.FC = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Julbord - Hallwylska Restaurang</title>
                <meta name="description" content="Celebrate the festive season with Hallwylska Restaurang's traditional Julbord. Join us for a delightful Christmas feast starting November 22nd." />
                <link rel="canonical" href="https://hallwylskarestaurang.com/julbord" />
                <meta property="og:title" content="Julbord - Hallwylska Restaurang" />
                <meta property="og:description" content="Celebrate the festive season with Hallwylska Restaurang's traditional Julbord. Join us for a delightful Christmas feast starting November 22nd." />
                <meta property="og:url" content="https://hallwylskarestaurang.com/julbord" />
                <meta name="twitter:title" content="Julbord - Hallwylska Restaurang" />
                <meta name="twitter:description" content="Celebrate the festive season with Hallwylska Restaurang's traditional Julbord. Join us for a delightful Christmas feast starting November 22nd." />
                
            </Helmet>
            <section className="julbord beige-bg pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xl-4 offset-xl-2 d-flex justify-content-center order-md-1 order-2">
                            <img src="/img/julbord.webp"
                                alt="Hallwylska's julbord"
                                className="mt-1 fly-left julbord__image"/>
                        </div>
                        <div className="col-md-6 col-xl-4 order-md-2 order-1 fly-right">
                            <h1 className="text-green">Julbord Hallwylska</h1>
                            <p>Vi välkomnar dig till Hallwylska för en magisk julupplevelse med vår klassiska julbuffé. Boka din plats redan idag!</p>
                            <h4 className="julbord__subtitle">Pris & tider</h4>
                            <p>
                                Lunch: 695:- (Ankomst 11.30 - 12.00) <br/>
                                Mellan: 895:- (Ankomst 15.00 - 15.30) <br/>
                                Kväll: 1095:- (Ankomst 19.30 - 20.30) <br/>
                                Julafton & Juldagen: 1095:- <br />
                                Lunch: 11.30 - 18.00 <br />
                                Mellan: 14.15 - 15.00
                            </p>
                            <h4 className="julbord__subtitle">Sittningstider</h4>
                            <p>
                                Alla sittningar varar i 2,5 timmar, förutom 11-14:a samt 18-21:a december då vi erbjuder fyra sittningar (11.00, 14.00, 17.00, 20.00/20.30) med två timmars sittningstid.
                            </p>
                            <h4 className="julbord__subtitle">Barnpriser</h4>
                            <p>
                                35:- per fyllt år (upp till 10 år). <br />
                                Hundar är välkomna.
                            </p>
                            <p className="text-center">
                                <Button text='Make a Reservation'
                                        class_type="primary"
                                        onClick={() => window.open('https://app.bokabord.se/reservation/?hash=6e756c0ef9100e3f835612c60f140eb0')}/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex">
                        <img src="/img/menu/julbord.png" alt="Hallwylska's julbord" className="mt-5 w-100"/>
                    </div>
            </section>
        </>
    );
  };